import { FC } from "react";
import Box, { BoxProps } from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import useTheme from "@mui/system/useTheme";
import useMediaQuery from "@mui/material/useMediaQuery";
import { convertDate } from "@helper/date";
import { getThumbnailAlt, getThumbnailUrl } from "@helper/media";
import { DrupalNode } from "next-drupal";
import Image from "next/image";
import Link from "next/link";
import East from "@mui/icons-material/East";
import { useTranslation } from "next-i18next";
import { isProd } from "@lib/costants";

const CATEGORY_ID_MAP = {
  companies: "d9004ef7-fa73-4130-9941-1a422ad7b7ff",
  publicAdministrations: "197de1f8-09cd-40f9-8c40-551b7c7cbe2f",
  nextNews: "715ad6dd-508d-4101-912a-e150ad9a5895",
  nextStories: isProd ? "90652ad9-62e4-4257-b763-7c6a1cc1cf2d" : "3f353e8e-b812-425b-821a-7af5ccda2150",
  nextFocus: "16250bdf-9de7-4b2e-9bad-cd8b97ec561d",
};

const COLOR_CATEGORY_ID_MAP = {
  [CATEGORY_ID_MAP.companies]: "#001A70",
  [CATEGORY_ID_MAP.publicAdministrations]: "#1057C8",
  [CATEGORY_ID_MAP.nextNews]: "#72C242",
  [CATEGORY_ID_MAP.nextStories]: "#4F9E30",
  [CATEGORY_ID_MAP.nextFocus]: "#0E6814",
};

type Props = {
  node: DrupalNode;
  href?: string;
};

const CARD_HOVER_STYLE = {
  "& .MuiBox-root.main": {
    transition: "opacity 0.25s ease-in-out",
    display: "none",
    opacity: "0",
  },
  "& .MuiBox-root.details": {
    transition: "opacity 0.25s ease-in-out",
    opacity: "1",
  },
  "& .MuiBox-root.bg": {
    transition: "background 0.25s ease-in-out",
    background: "#fff",
    opacity: "0.9",
  },
};

const CardArticleContent: FC<Props & BoxProps> = ({ node, href, sx }) => {
  const isMobile = useMediaQuery(useTheme().breakpoints.down("lg"));
  const { t } = useTranslation();
  const description = node?.description || node?.field_paragraph?.at(0)?.field_hero_item?.at(0)?.field_description?.value as string | TrustedHTML || '';
  const categoryId = node?.field_category?.id;
  const categoryColor =
    categoryId && COLOR_CATEGORY_ID_MAP[categoryId]
      ? COLOR_CATEGORY_ID_MAP[categoryId]
      : "#001A70";
  const image = node?.field_article_image
    ? getThumbnailUrl(node.field_article_image)
    : "/assets/images/img-placeholder-01.jpg";
  const date: string = convertDate(node?.created, node?.path?.langcode ?? "it");

  return (
    <>
      <Box
        className="article-card-content"
        sx={{
          userSelect: "none",
          backgroundColor: categoryColor,
          width: "100%",
          paddingBottom: "100%",
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "flex-end",
          transition: "box-shadow 0.25s ease-in-out",
          "&:hover": {
            boxShadow: isMobile
              ? ""
              : "0px 8px 18px -6px rgba(0, 26, 112, 0.4), 0px 12px 42px -4px rgba(0, 26, 112, 0.4)",
          },
          ...sx,
        }}
      >
        <Box
          sx={{
            overflow: "hidden",
            position: "absolute",
            top: "0",
            width: "100%",
            height: "100%",
            "&:hover": isMobile ? {} : CARD_HOVER_STYLE,
          }}
        >
          {image && (
            <Image
              style={{ objectFit: "cover", zIndex: "0", userSelect: "none" }}
              src={image}
              alt={getThumbnailAlt(node.field_article_image)}
              fill
              sizes="(max-width: 1024px) 100vw, 33vw"
              quality={100}
            />
          )}

          <Box
            className="main"
            sx={{
              zIndex: "2",
              position: "absolute",
              top: "0",
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
              alignContent: "center",
              color: "#fff",
              padding: "8%",
              textAlign: "center",
            }}
          >
            {date && <Typography
              sx={{
                fontWeight: "500",
                fontSize: "12px",
                marginBottom: "12px",
              }}
            >
              {date}
            </Typography>}

            <Typography
              sx={{
                fontWeight: "600",
                fontSize: "19px",
              }}
            >
              {node?.title}
            </Typography>
          </Box>

          <Box
            className="details"
            sx={{
              zIndex: "3",
              position: "absolute",
              top: "0",
              width: "100%",
              height: "100%",
              background: "transparent",
              display: "flex",
              flexDirection: "column",
              justifyContent: "start",
              alignContent: "center",
              color: "#000",
              padding: "8%",
              textAlign: "center",
              opacity: "0",
              transition: "opacity 0.25s ease-in-out",
              "&:hover": isMobile
                ? {}
                : {
                  opacity: 1,
                },
              overflow: "hidden",
            }}
          >
            <Typography
              sx={{
                color: "gray",
                zIndex: "2",
                fontWeight: "500",
                fontSize: "12px",
                marginBottom: "12px",
              }}
            >
              {date}
            </Typography>

            <Typography
              color="secondary"
              sx={{
                zIndex: "2",
                fontWeight: "600",
                fontSize: "19px",
              }}
            >
              {node?.title}
            </Typography>

            <div
              style={{
                zIndex: "2",
                paddingTop: "10px",
                fontWeight: "400",
                fontSize: "14px",
                position: "relative",
              }}
              dangerouslySetInnerHTML={{ __html: description }}
            />
          </Box>

          <Box
            className="bg"
            sx={{
              zIndex: "1",
              width: "100%",
              height: "100%",
              position: "absolute",
              top: "0",
              left: "0",
              opacity: "0.8",
              background: `linear-gradient(0deg, ${categoryColor} 0%, rgba(255,255,255,0) 100%)`,
            }}
          />
        </Box>
      </Box>

      {isMobile ? (
        <>
          <div
            style={{
              paddingTop: "10px",
              fontWeight: "400",
              fontSize: "16px",
              position: "relative",
              color: "#000",
              marginTop: "1rem",
              marginBottom: "1rem",
              // display: 'inline-block',
              // '-webkit-box-orient': 'vertical',
              // '-webkit-line-clamp': '4',
              // overflow: 'hidden',
            }}
            dangerouslySetInnerHTML={{ __html: description }}
          />

          <Link
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "start",
            }}
            href={href ?? ""}
            passHref
          >
            <East sx={{ mr: "1rem" }} />
            <Typography color="primary" textTransform="uppercase">
              {t("news.learnMore")}
            </Typography>
          </Link>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

const CardArticle: FC<Props & BoxProps> = ({ node, sx }) => {
  const isMobile = useMediaQuery(useTheme().breakpoints.down("lg"));
  const href = node?.href || node?.path?.alias?.replace("/news/", "/next-journal/");
  if (isMobile)
    return (
      <Box sx={{ marginBottom: 32 }}>
        <CardArticleContent sx={sx} node={node} href={href} />
      </Box>
    );

  return (
    <Link
      style={isMobile ? { marginBottom: "4rem" } : {}}
      href={href ?? ""}
      passHref
    >
      <CardArticleContent sx={sx} node={node} href={href} />
    </Link>
  );
};

export default CardArticle;
