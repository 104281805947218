import { ThemeProvider } from "@mui/material/styles";
import { theme } from "@styles/mui/theme";
import { DarkModeContextProvider } from "@contexts/darkMode";
import { AppProps } from "next/app";
import { appWithTranslation } from "next-i18next";
import { useEffect } from "react";
import PathName from "@lib/path-name";
import "@styles/global.scss";
import NextNProgress from "nextjs-progressbar";
import { SessionProvider } from "next-auth/react";
import { AppCacheProvider } from "@mui/material-nextjs/v13-pagesRouter";
import CssBaseline from "@mui/material/CssBaseline";
import themeLongForm from "@styles/mui/themeLongForm";

function App(props: AppProps) {
  const {
    Component,
    pageProps: { session, ...pageProps },
  } = props;
  const path = "page-" + PathName();

  useEffect(() => {
    if (typeof window !== "undefined") {
      removeClassByPrefix(document.body, "page-");
      document.body.className = path;
    }

    // app height - vh
    const appHeight = () => {
      const doc = document.documentElement;
      if (!doc) return;
      doc.style.setProperty("--app-height", `${window.innerHeight}px`);
    };
    window.addEventListener("resize", appHeight);
    appHeight();
  }, [path]);

  function removeClassByPrefix(node, prefix) {
    let regx = new RegExp("\\b" + prefix + "[^ ]*[ ]?\\b", "g");
    node.className = node.className.replace(regx, "");
    return node;
  }

  const themeMapping = {
    "node--longform": themeLongForm,
  };

  const currentTheme = themeMapping[pageProps?.node?.type] || theme;

  return (
    <SessionProvider session={session}>
      <AppCacheProvider {...props}>
        <DarkModeContextProvider>
          <ThemeProvider theme={currentTheme}>
            <CssBaseline />
            <NextNProgress
              options={{ easing: "ease", speed: 500, showSpinner: false }}
              height={3}
              color="#4F9E30"
              startPosition={0.3}
              stopDelayMs={200}
              showOnShallow={true}
            />
            <Component {...pageProps} />
          </ThemeProvider>
        </DarkModeContextProvider>
      </AppCacheProvider>
    </SessionProvider>
  );
}

export default appWithTranslation(App);
