import { componentTypes, validatorTypes } from '@data-driven-forms/react-form-renderer';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import { NextRouter } from 'next/router';
import {
    areaInteresse as areaInteresseIt,
    generationChannel_formPerSales,
    ipa as ipaIt,
    provenanceForm_sales,
    role as roleIt,
    rolePA as rolePAIt,
    salesFormAgreement_0,
    settore as settoreIt,
    settorePA as settorePAIt,
    ulterioriAreeInteresse as ulterioriAreeInteresseIt,
    validationMessages as validationMessagesIt
} from './formFieldsIt';

import { formTargetServiziMap, getFormTarget, getReferrerPage } from '@helper/form/formTarget';
import { createFilterOptions } from '@mui/material/Autocomplete';
import { defaultInputProps, labelProps } from './defaultProps';
import {
    validationMessages as validationMessagesEng
} from './formFieldsEng';
import { email_blacklist } from './options/emails_blacklists';

const resolveLabel = (field) => {
    /** Fix &#x27 */
    const label = field?.label ? `${field.label}`.replace('&#x27;', "'") : '';

    if (field?.required) {
        return `${label}*`
    };
    return label;
}

const calcColumn = (fields) => {
    return 12 / fields
};

//// FORM PER SALES
const isFormPerSalesCase = (router: NextRouter) => router?.asPath === '/form-per-sales/';

const companyOrPAsubFields = [
    {
        ...roleIt,
        FormFieldGridProps: { lg: 6 },
        condition: {
            when: 'azienda_o_pubblica_amministrazione_',
            is: 'Azienda',
            then: { set: { 'ruolo___pa': '', 'settore___pa_solo_per_form_b2g': '', 'codice_ipa': '' } }
        },
    },
    {
        ...rolePAIt,
        FormFieldGridProps: { lg: 6 },
        condition: {
            when: 'azienda_o_pubblica_amministrazione_',
            is: 'Ente Pubblico',
            then: { set: { 'ruolo': '', 'settore___azienda_solo_per__form_b2b': '' } }
        },
    },
    {
        ...settoreIt,
        FormFieldGridProps: { lg: 6 },
        condition: {
            when: 'azienda_o_pubblica_amministrazione_',
            is: 'Azienda'
        },
    },
    {
        ...settorePAIt,
        FormFieldGridProps: { lg: 6 },
        condition: {
            when: 'azienda_o_pubblica_amministrazione_',
            is: 'Ente Pubblico'
        },
    },
    {
        ...ipaIt,
        FormFieldGridProps: { lg: 6 },
        condition: {
            when: 'azienda_o_pubblica_amministrazione_',
            is: 'Ente Pubblico'
        },
    },
]
////

export const getFormSchema = (data, router: NextRouter) => {
    const validationMessages = router.locale === 'it' ? validationMessagesIt : validationMessagesEng;
    const fields = data?.fieldGroups?.flatMap((fieldGroup, index) => {
        const columns = calcColumn(fieldGroup.fields?.length) ?? 1;

        return fieldGroup.fields?.map((field) => {
            if (isFormPerSalesCase(router)) {
                switch (field.name) {
                    case ('consenso_4___autorizzazione_al_ricontatto'): {
                        return salesFormAgreement_0;
                    }
                    case ('urlfrom__c'): {
                        return provenanceForm_sales;
                    }
                    case ('canale_di_generazione'): {
                        return generationChannel_formPerSales;
                    }
                    case ('azienda_o_pubblica_amministrazione_'): {
                        return [
                            formatField(field, columns, router, validationMessages, index),
                            ...companyOrPAsubFields,
                        ];
                    }
                    default: {
                        return formatField(field, columns, router, validationMessages, index);
                    }
                }
            }

            return formatField(field, columns, router, validationMessages, index);
        })
            .flat()
            ?? [formatField(fieldGroup, columns, router, validationMessages, index)];
    }) ?? [];
    return { fields };
};

const formatField = (field, columns, router: NextRouter, validationMessages, index: number) => {
    const validation = getFieldValidation(field, validationMessages);
    const InputLabelProps = labelProps;

    const label = resolveLabel(field);
    const placeholder = field.description ? field.description : field?.placeholder ? `${field.placeholder}`.replace('&#x27;', "'") : label;

    console.log('field: ' + field.placeholder, field);
    switch (field.fieldType) {
        case 'single_line_text':
            let defaultValue = '';

            if (field.name == 'campagna_mrk' && router?.query?.req) {
                defaultValue = router?.query?.req.toString();
            }

            if (field.name === 'ultimo_touchpoint' && typeof window !== 'undefined') {
                const referrer = getReferrerPage();
                defaultValue = referrer ?? 'Web contattaci';
            }

            return {
                component: componentTypes.TEXT_FIELD,
                name: field.name,
                label,
                placeholder,
                isRequired: field.required,
                FormFieldGridProps: { lg: columns },
                hideField: field.hidden,
                InputLabelProps: { ...InputLabelProps },
                ...defaultInputProps,
                validate: validation,
                initialValue: defaultValue
            };

        case 'email':
            return {
                component: componentTypes.TEXT_FIELD,
                name: field.name,
                label,
                placeholder,
                isRequired: field.required,
                FormFieldGridProps: { lg: columns },
                hideField: field.hidden,
                InputLabelProps: { ...InputLabelProps },
                ...defaultInputProps,
                validate: validation
            };

        case 'phone':
        case 'mobile_phone':
            return {
                component: componentTypes.TEXT_FIELD,
                name: field.name,
                label,
                placeholder,
                isRequired: field.required,
                FormFieldGridProps: { lg: columns },
                hideField: field.hidden,
                InputLabelProps: { ...InputLabelProps },
                ...defaultInputProps,
                validate: validation
            };

        case 'multi_line_text':
            return {
                component: componentTypes.TEXT_FIELD,
                name: field.name,
                label,
                placeholder,
                isRequired: field.required,
                FormFieldGridProps: { lg: columns },
                hideField: field.hidden,
                InputLabelProps: { ...InputLabelProps },
                ...defaultInputProps,
                multiline: true,
                validate: validation
            };

        case 'single_checkbox':
            return {
                component: componentTypes.SWITCH,
                name: field.name,
                label,
                placeholder,
                isRequired: field.required,
                FormFieldGridProps: { lg: columns },
                FormLabelProps: { sx: { color: 'grey.800' } },
                hideField: field.hidden,
                initialValue: field.defaultValue === 'true' ? true : false,
                ...defaultInputProps,
                validate: validation
            };

        case 'radio':
        case 'dropdown':
            const dropdown = {
                component: componentTypes.SELECT,
                name: field.name,
                label,
                placeholder,
                isRequired: field.required,
                FormFieldGridProps: { lg: columns },
                hideField: field.hidden,
                TextFieldProps: { variant: "standard", InputLabelProps: { ...InputLabelProps } },
                simpleValue: true,
                inputProps: { ...defaultInputProps, },
                options: field.options.filter(option => option.value),
                validate: validation,
                isOptionEqualToValue: (option, value) => option.value === value,
            }

            if (['job_title___tendina', 'job_title__facoltativo_'].includes(field.name)) {
                return {
                    ...dropdown,
                    isClearable: true,
                    isSearchable: true,
                    noOptionsText: router.locale === 'it' ? 'Nessun risultato' : 'No results',
                    filterOptions: createFilterOptions({ matchFrom: 'start', }),
                }
            }

            if (field.name == 'scegli_il_servizio___tendina' && typeof window !== 'undefined') {
                const formTarget = getFormTarget();

                const initialValue = formTargetServiziMap[formTarget?.servizi] ?? "Nessuno";

                return {
                    ...dropdown,
                    initialValue,
                };
            }

            if (/* isStatic &&  */field.name == 'tipologia_richiesta__c') {
                return { ...areaInteresseIt, FormFieldGridProps: { lg: columns } }
            }

            return dropdown;

        case 'multiple_checkboxes':

            if (/* isStatic &&  */field.name == 'ulteriori_aree_d_interesse__c') {
                return { ...ulterioriAreeInteresseIt, FormFieldGridProps: { lg: columns } }
            }

            return {
                component: componentTypes.SELECT,
                name: field.name,
                label,
                placeholder,
                FormFieldGridProps: { lg: columns },
                TextFieldProps: { variant: "standard", InputLabelProps: { ...InputLabelProps } },
                ...defaultInputProps,
                isRequired: false,
                isMulti: true,
                simpleValue: true,
                closeMenuOnSelect: false,
                disableCloseOnSelect: true,
                componentsProps: { paper: { className: 'contactForm-Paper', } },
                isOptionEqualToValue: (option, value) => option.value === value,
                renderOption: (props, option, state) => {
                    return (
                        <MenuItem {...props}>
                            <Checkbox checked={state?.selected} />
                            <ListItemText primary={option?.label} />
                        </MenuItem>
                    )
                },
                options: [...field.options]
            };

        case 'file':
            return {
                component: 'file-upload',
                name: field.name,
                label,
                type: 'file',
                validate: validation,
                isRequired: field.required,
            };

        default:
            return {
                component: 'plain-text',
                name: `${field.richTextType}_${index}`,
                label: field.richText,
                gridProps: { mt: 50, textAlign: 'center' },
                TypographyProps: { sx: { color: 'grey.800' } }
            };
    }
}

const getFieldValidation = (field, validationMessages) => {
    const validation = [];

    if (field.required) {
        validation.push({ type: validatorTypes.REQUIRED, message: validationMessages.required });
    }

    switch (field.fieldType) {
        case 'single_line_text':
            if (['firstname', 'lastname'].includes(field.name)) {
                validation.push({ type: validatorTypes.PATTERN, pattern: /^[A-Z ]+$/i, message: validationMessages.standard });
            } else if (field.name === 'zip') {
                validation.push({ type: validatorTypes.PATTERN, pattern: /^[0-9]{5}([- /]?[0-9]{4})?$/, message: validationMessages.cap });
            } else if (field.name === 'partita_iva') {
                validation.push({ type: validatorTypes.EXACT_LENGTH, threshold: 11, message: validationMessages.piva.length });
            }
            break;

        case 'email':
            if (field.validation?.useDefaultBlockList) {
                validation.push((value) => email_blacklist.includes(value.split('@')[1]) ? validationMessages.email.blocklist : null)
            }
            validation.push({ type: validatorTypes.PATTERN, pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, message: validationMessages.standard });
            break;

        case 'mobile_phone':
            validation.push(
                { type: validatorTypes.PATTERN, pattern: /^[\d|\+|\(]+[\)|\d|\s|-]*[\d]$/, message: validationMessages.standard },
                { type: validatorTypes.MIN_LENGTH, threshold: 8, message: validationMessages.phone.min },
                { type: validatorTypes.MAX_LENGTH, threshold: 15, message: validationMessages.phone.max }
            );

            break;

        case 'file':
            validation.push(
                { type: 'file-size', maxSize: 5e+6 },
            );
            break;

        default:
            break;
    }

    return validation;
};

export default getFormSchema