// LongFormContent.tsx
import { paragraphsMap } from "@helper/paragraph";
import { absoluteUrl } from "@lib/utils";
import { Box, Typography, useMediaQuery } from "@mui/material";
import theme from "@styles/mui/theme";
import { DrupalNode } from "next-drupal";
import Image from "next/image";
import React from "react";
import styles from "./LongFormModal.module.scss";
import Marquee from "react-fast-marquee";


interface LongFormContentProps {
  fieldData: any;
  modal: boolean;
  node: DrupalNode;
  onClick: () => void;
}

const LongFormContent: React.FC<LongFormContentProps> = ({
  fieldData,
  modal,
  node,
  onClick,
}) => {
  const { field_title, field_icon, field_number, field_modal_content } =
    fieldData;
  const imageUrl = absoluteUrl(field_icon?.field_media_image?.uri?.url || "");
  const imageAlt = field_icon?.field_media_image?.filename || "";

  const isMobile: boolean = useMediaQuery(theme.breakpoints.down("lg"));

  return (
    <Box className={styles.container} onClick={onClick}>
      <Box className={styles.header}>
        <Box className={`${styles.lg1} ${styles.headerIcon}`}>
          {imageUrl && (
            <Image
              src={imageUrl}
              alt={imageAlt}
              width={40}
              height={40}
              quality={100}
            />
          )}
        </Box>
        <Box className={`${styles.lg10} ${styles.headerTitle}`}>

          {field_title && (
            <Box width="100%">
              {isMobile ? (
                <Marquee style={{ overflow: "hidden" }}>
                  <Typography variant="h3" pr={32}>
                    {field_title}
                  </Typography>
                </Marquee>
              ) : (
                <Typography variant="h3">{field_title}</Typography>
              )}
            </Box>
          )}
        </Box>
        {!isMobile && (
          <Box className={`${styles.lg1} ${styles.versionBox}`}>
            <Typography variant="h3" className={styles.number}>
              {field_number}
            </Typography>
          </Box>
        )}
      </Box>
      <Box className={styles.row}>
        <Box className={styles.lg1} />
        <Box className={`${styles.lg10}`}>
          <Box className={styles.stripedSection} />
        </Box>
        <Box className={styles.lg1} />
      </Box>
      <Box className={`${styles.row} ${styles.rowContent}`}>
        <Box className={styles.lg1} />
        <Box className={styles.lg10}>
          <Box className={styles.content}>
            {field_modal_content?.field_paragraph?.map((item, index) => {
              const ParagraphComponent = paragraphsMap[item.type];
              if (!modal && index === 0 && item.type === "paragraph--text") {
                return (
                  <LongFormInfoText
                    key={index}
                    fieldData={item}
                    isMobile={isMobile}
                    field_number={field_number}
                  />
                );
              }
              if (modal && ParagraphComponent) {
                if (item.type === "paragraph--text")
                  return (
                    <LongFormInfoText
                      key={index}
                      fieldData={item}
                      isMobile={isMobile}
                      field_number={field_number}
                    />
                  );
                return (
                  <ParagraphComponent
                    key={index}
                    fieldData={item}
                    node={node}
                    isModal={true}
                  />
                );
              }
            })}
          </Box>
        </Box>
        <Box className={styles.lg1} />
      </Box>
    </Box>
  );
};

export default LongFormContent;

const LongFormInfoText = ({ fieldData, isMobile, field_number }) => {
  const { field_text_title, field_text_content } = fieldData;
  return (
    <>
      {isMobile && (
        <Box className={`${styles.versionBox}`}>
          <Typography variant="h3" className={styles.number}>
            {field_number}
          </Typography>
        </Box>
      )}
      {field_text_title && (
        <Box className={styles.headerBox}>
          <Typography
            variant="h1"
            component={"div"}
            className={styles.modalTitle}
          >
            {field_text_title}
          </Typography>
        </Box>
      )}
      <Typography
        variant="subtitle"
        component={"div"}
        className={styles.description}
        dangerouslySetInnerHTML={{
          __html: field_text_content?.processed,
        }}
      />
      <Box className={styles.line}></Box>
    </>
  );
};
