import { NextRouter, useRouter } from "next/router";
import Link from "next/link";

import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import Logo from '@components/UI/Logo/Logo';
import Row from "@components/Modules/Utils/Row/Row";
import SwitchDarkMode from "@components/Modules/Utils/SwitchDarkMode/SwitchDarkMode";
import { FC } from "react";
import React from "react";

const EmptyLayout: FC<any> = ({children, heading = null, wayOut=null}) => {
  const router: NextRouter = useRouter();
  return (
    <Box className="emptyLayout" component="main">
      <Container maxWidth={false}>
        <Row justifyContent="space-between" alignItems="center">

          <Grid item xs={12} lg={4}>
            <Link href="/" aria-label="Logo Edison NEXT">
                <Logo />
            </Link>
          </Grid>

          {heading && (
            <>
              <Grid item xs={12} lg={3} textAlign={'center'} mb={{xs: 24, lg: 0}}>
                <Typography className={'heading'} variant="subtitle" component="h3">{heading}</Typography>
                {wayOut && 
                <Link href={wayOut.href}>
                  <Typography className={'wayout'} variant="bodyL" component="span">{wayOut.label}</Typography>
                </Link>
                }
              </Grid>
              <Grid item lg={5}/>
            </>
          )}
        </Row>
        
      </Container>
      <Container maxWidth={false} style={{position: 'relative'}}>
        {children}

          <Row justifyContent="space-between" alignItems="center">

            <Grid item xs={12} lg={6} textAlign={'center'}>
              <Box className="emptyLayoutFooter">
                <SwitchDarkMode layout="empty" />
              </Box>
            </Grid>
            <Grid item xs={12} lg={6} textAlign={{ xs: 'center', lg: 'right' }}>
              <Typography variant="bodyS" component="p" fontWeight={300}>
                Società a socio unico soggetta all’attività di direzione e coordinamento di Edison S.p.A.<br />
                Capitale Soc. euro 330.500.000 i.v. - Reg. Imprese di TORINO C.F. 13032970157 - PEC: edisonnext@pec.edison.it<br />
                <Link href={ router.locale === 'it' ? '/it/informazioni-legali-certificazioni' : '/en/legal-information-and-certifications'} passHref aria-label="legal">
                    <strong>Informazioni legali e certificazioni</strong>
                </Link>
                {' '}
                con i dettagli di contatto per singola legal entity
              </Typography>
            </Grid>

          </Row>
      </Container>

    </Box>
  )
}

export default EmptyLayout