import React from "react";
import Head from "next/head";
import Script from "next/script";
import { NextRouter, useRouter } from "next/router";
import { DrupalNode } from "next-drupal";
import { absoluteUrl, frontendUrl } from "@helper/url";

interface MetaTagsProps {
  node?: DrupalNode;
}

const getOgImgUrl = (node: DrupalNode) => {
  const articleImgData = node?.field_article_image?.thumbnail?.uri?.url || "";
  const fieldHeroItem = node?.field_paragraph
    ?.find((p) => p?.type === "paragraph--hero")
    ?.field_hero_item?.at(0) as DrupalNode;
  const heroOgImgData: string =
    fieldHeroItem?.field_image?.thumbnail?.uri?.url ||
    fieldHeroItem?.field_immagine_;
  const articleOgImgData: string = node?.field_paragraph
    ?.find((p) => p?.type === "paragraph--news_content")
    ?.field_newscontent_items?.find(
      (p) => p?.type === "paragraph--news_content_item_text_image"
    )?.field_newscontent_img?.field_media_image?.uri?.url;

  const ogImgData = articleImgData || heroOgImgData || articleOgImgData;

  return ogImgData
    ? absoluteUrl(ogImgData)
    : frontendUrl("/assets/images/01_share.jpg");
};

const MetaTags = ({ node }: MetaTagsProps) => {
  const router: NextRouter = useRouter();

  const ogImgUrl = getOgImgUrl(node);

  const metaTitle = node?.field_meta_title
    ? node?.field_meta_title
    : node?.title || node?.name;
  const metaDescription = node?.field_meta_description
    ? node?.field_meta_description
    : "";
  const metaKeywords = node?.field_meta_keywords
    ? node?.field_meta_keywords
    : "";

  const canonicalURL = `${process.env.NEXT_PUBLIC_BASE_URL}/${router.locale}${router.asPath}`;

  return (
    <>
      <Script
        id="google-analytics"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                  })(window,document,'script','dataLayer', "${process.env.NEXT_PUBLIC_TAG_MANAGER}")`,
        }}
      />

      {process.env.NEXT_PUBLIC_MICROSOFT_CLARITY && (
        <Script
          id="microsoft-clarity-init"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
            (function(c,l,a,r,i,t,y){
                c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
            })(window, document, "clarity", "script", "${process.env.NEXT_PUBLIC_MICROSOFT_CLARITY}");
            `,
          }}
        />
      )}

      <Script
        id="hs-script-loader"
        strategy="afterInteractive"
        data-cookieconsent="statistics"
        type="text/plain"
        src="//js-eu1.hs-scripts.com/26099279.js"
      />

      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=5"
        />
        <meta
          name="robots"
          content={
            node?.field_meta_noindex || node?.field_disabled
              ? "noindex, nofollow"
              : "index, follow"
          }
        />

        <title>{metaTitle}</title>
        <link rel="canonical" href={canonicalURL} />
        {metaDescription && (
          <meta name="description" content={metaDescription} />
        )}
        {metaKeywords && <meta name="keywords" content={metaKeywords} />}

        <meta property="og:site_name" content="Edison NEXT" />
        <meta property="og:title" content={metaTitle} />
        <meta property="og:description" content={metaDescription || ""} />
        <meta property="og:image" content={ogImgUrl} />
        <meta property="og:url" content={canonicalURL} />

        <link
          rel="manifest"
          href="/manifest.json"
          crossOrigin="use-credentials"
        />
        <link rel="apple-touch-icon" href="/logo-96x96.png" />
        <meta name="theme-color" content="#001A70" />
        <meta name="apple-mobile-web-app-status-bar" content="#001A70" />

      </Head>
    </>
  );
};

export default MetaTags;
